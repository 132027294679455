<template>
  <div id="task--customer--service">
    <div id="container--task-general">
      <div id="filters--notes" class="child">
        <b-row>
          <b-col>
            <FiltersYearNotes @selectedYear="selectedYear" />
          </b-col>
          <b-col></b-col>
          <b-col>
            <SelectCustom
              :options="optionsSelect"
              placeholder="Select option"
              @selected="selectedStatusDone"
              :firstItemDefault="true"
            />
          </b-col>
        </b-row>
      </div>
      <div id="container--task" class="child">
        <b-list-group>
          <template v-if="haveTasks">
            <b-list-group-item
              v-for="(task, index) in dataTasks"
              :key="index"
              style="border-width: 2px !important"
              class="bg-transparent rounded-lg my-50 p-1 px-1 px-md-2"
              :class="{
                'border-success': !!task.done,
                'border-warning': !task.done,
              }"
            >
              <b-row class="font-size-md">
                <b-col
                  cols="6"
                  class="d-flex justify-content-start align-items-center"
                >
                  <feather-icon
                    @click="validDoneAction(task) ? onDoneTask(task.id) : null"
                    :icon="!task.done ? 'CircleIcon' : 'CheckCircleIcon'"
                    size="25"
                    class="mr-1"
                    :class="{
                      'text-success': !!task.done,
                      'text-secondary cursor-pointer hover-me': !task.done,
                    }"
                    v-b-tooltip.hover.v-success="
                      !!task.done ? 'COMPLETED' : 'TO COMPLETED'
                    "
                  />
                  <p :class="!!task.done ? 'text--through' : ''" class="m-0">
                    <strong>Subject:&nbsp;</strong> {{ task.subject }}
                  </p>
                </b-col>
                <b-col
                  cols="3"
                  class="d-flex justify-content-center align-items-center font-weight-bolder"
                >
                  {{ task.date | formatRealTime }}
                </b-col>
                <b-col
                  cols="3"
                  class="d-flex justify-content-end align-items-center"
                >
                  <!--MORE OPTIONS-->
                  <b-dropdown
                    size="sm"
                    variant="transparent"
                    toggle-class="text-decoration-none"
                    class="cursor-default"
                    no-caret
                  >
                    <template #button-content>
                      <feather-icon
                        v-b-tooltip.hover.left="'More options'"
                        icon="MoreHorizontalIcon"
                        size="20"
                        class="hover-me cursor-pointer"
                        :class="isDarkSkin ? 'text-white' : 'text-dark'"
                      ></feather-icon>
                    </template>
                    <b-dropdown-item
                      href="#"
                      @click="onModalEditTaskOpen(task, true)"
                      v-if="!task.done"
                    >
                      <feather-icon
                        icon="EditIcon"
                        size="15"
                        class="mr-1 text-warning"
                      />
                      <span class="text-warning">Edit Task</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      href="#"
                      @click="onModalEditTaskOpen(task, false)"
                    >
                      <feather-icon
                        icon="EyeIcon"
                        size="15"
                        class="mr-1 text-info"
                      />
                      <span class="text-info">View Task</span>
                    </b-dropdown-item>
                    <b-dropdown-item href="#" @click="onDeleteTask(task.id)">
                      <feather-icon
                        icon="Trash2Icon"
                        size="15"
                        class="mr-1 text-danger"
                      />
                      <span class="text-danger">Delete Task</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
            </b-list-group-item>
          </template>
          <div
            v-else
            class="w-100 d-flex justify-content-center align-items-center py-5 position-relative rounded-lg"
            :class="isDarkSkin ? 'bg-dark' : null"
            style="height: 375px"
          >
            <img
              :src="require('@/assets/images/icons/empty-notes.svg')"
              class="my-0 my-md-2"
              style="opacity: 0.2"
              width="150px"
              height="150px"
            />
            <span v-if="!isLoading" class="custom-span"
              >No
              {{
                currentTypeTask.id === 0 ? "Tasks" : currentTypeTask.text
              }}</span
            >
            <b-spinner
              class="custom-spinner"
              v-else
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </b-list-group>
      </div>
      <div id="actions--task" class="child">
        <b-button
          size="md"
          variant="primary"
          class="mr-1"
          @click="openListCompletedTasks"
        >
          <feather-icon icon="EyeIcon" class="mr-1" />VIEW COMPLETED TASKS
        </b-button>
        <b-button
          size="md"
          class="new--task"
          v-if="!onlyRead"
          :disabled="isResponsibleAccount"
          @click="openAddTaskCreate"
        >
          <feather-icon icon="PlusIcon" />
          <span> New Task</span>
        </b-button>
      </div>
    </div>

    <AddTaskModal
      v-if="openAddTaskController"
      v-bind="modalEditScope.attributes"
      v-on="modalEditScope.events"
    />
    <ListCompletedTasksModal
      v-if="listCompletedTasksController"
      v-bind="modalListCompletedTaskScope.attributes"
      v-on="modalListCompletedTaskScope.events"
    />
  </div>
</template>
<script>
import FiltersYearNotes from "@/views/ce-digital/sub-modules/customer-service/views/clients/dashboard/options/task-notes-module/components/FiltersYearNotes.vue";
import SelectCustom from "@/views/ce-digital/sub-modules/customer-service/views/clients/dashboard/options/task-notes-module/components/SelectCustom.vue";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import AddTaskModal from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/AddTaskModal.vue";
import ListCompletedTasksModal from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/ListCompletedTasksModal.vue";

import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "Tasks",
  props: {
    currentUser: {
      required: true,
    },
    client: {
      required: true,
    },
    notes: {
      required: false,
    },
    moduleId: {
      required: true,
    },
  },
  components: {
    FiltersYearNotes,
    SelectCustom,
    AddTaskModal,
    ListCompletedTasksModal,
  },
  data() {
    return {
      openAddTaskController: false,
      isLoading: false,
      modalAddOrEdit: {
        taskData: {},
        toEdit: false,
        showMood: false,
        aid: null,
        aname: null,
        sid: null,
        sname: null,
        nameClient: null,
      },
      optionsSelect: [
        {
          text: "All tasks",
          value: null,
          badge: {
            variant: "primary",
            value: 2,
          },
        },
        {
          text: "Completed tasks",
          value: 1,
          badge: {
            variant: "success",
            value: 0,
          },
        },
        {
          text: "Pending tasks",
          value: 0,
          badge: {
            variant: "warning",
            value: 0,
          },
        },
      ],
      dataTasks: [],
      filters: {
        status_done: null,
        year: 0,
      },
      listCompletedTasksController: false,
      currentTypeTask: {
        id: 0,
        text: "",
      },
    };
  },
  filters: {
    formatRealTime(value) {
      const formatValue = moment(moment(value).format("YYYY-MM-DD"));
      const now = moment(moment().format("YYYY-MM-DD"));
      const diff = formatValue.diff(now, "days");

      switch (diff) {
        case -3:
          value = "3 days ago";
          break;
        case -2:
          value = "2 days ago";
          break;
        case -1:
          value = "Yesterday";
          break;
        case 0:
          value = "Today";
          break;
        case 1:
          value = "Tomorrow";
          break;
        default:
          value = moment(value).format("MMMM DD, YYYY");
          break;
      }

      return value;
    },
  },
  computed: {
    modalListCompletedTaskScope() {
      return {
        attributes: {
          "name-client": this.client.client_name,
        },
        events: {
          close: this.closeListCompletedTasks,
        },
      };
    },
    modalEditScope() {
      return {
        attributes: this.modalAddOrEdit,
        events: {
          close: () => {
            this.openAddTaskController = false;
            this.modalAddOrEdit.taskData = {};
            this.modalAddOrEdit.showMood = false;
          },
          reload: this.getAllTasks,
        },
      };
    },
    haveTasks() {
      return this.dataTasks.length > 0;
    },
    onlyRead() {
      return this.moduleId === 18;
    },
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
  },
  watch: {
    client: {
      handler(value) {
        if (value) {
          this.modalAddOrEdit.aid = this.client.advisor_id;
          this.modalAddOrEdit.sid = this.client.supervisor_id;
          this.modalAddOrEdit.sname = this.client.supervisor_name;
          this.modalAddOrEdit.nameClient = this.client.client_name;
          this.modalAddOrEdit.aname = this.client.advisor_name;
        }
      },
      deep: true,
      immediate: true,
    },
    filters: {
      async handler(value) {
        await this.getAllTasks();
      },
      deep: true,
    },
  },
  async created() {},
  methods: {
    validDoneAction(task) {
      const { role_id, user_id } = this.currentUser;
      const userIdValid = task.user_id == user_id;
      const roleIdValid = role_id == 2 || role_id == 1;
      return (userIdValid || roleIdValid) && !this.onlyRead && !task.done;
    },
    async getAllTasks() {
      try {
        this.isLoading = true;
        const { data } = await ClientsOptionsServices.getAllTasksGrid({
          id: this.$route.params.idClient,
          user_id: this.currentUser.user_id,
          ...this.filters,
        });
        this.dataTasks = data.data;
        this.optionsSelect.forEach((item) => {
          if (item.value === null) {
            item.badge.value = data.totales.total_tasks;
          }
          if (item.value === 1) {
            item.badge.value = data.totales.completed_tasks;
          }
          if (item.value === 0) {
            item.badge.value = data.totales.pending_tasks;
          }
        });
        this.isLoading = false;
      } catch (ex) {
        this.isLoading = false;
        console.log(ex);
      }
    },
    openAddTaskCreate() {
      this.openAddTaskController = true;
      this.modalAddOrEdit.toEdit = false;
      this.modalAddOrEdit.taskData = {};
    },
    async onDoneTask(id) {
      try {
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          this.addPreloader();
          const data = await ClientsOptionsServices.checkTaskCredit({
            id,
          });
          if (data.status === 200) {
            this.removePreloader();
            this.showSuccessSwal();
            await this.getAllTasks();
          }
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    onModalEditTaskOpen(item, edit) {
      this.modalAddOrEdit.taskData = item;
      this.modalAddOrEdit.toEdit = edit;
      this.modalAddOrEdit.showMood = !edit;
      this.openAddTaskController = true;
    },
    async onDeleteTask(id) {
      try {
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          this.addPreloader();
          const data = await ClientsOptionsServices.deleteTaskCredit({
            id,
          });
          if (data.status === 200) {
            this.removePreloader();
            this.showSuccessSwal();
            await this.getAllTasks();
          }
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    selectedStatusDone(option) {
      const text = option.value === 1 ? "Completed" : "Pending";
      this.currentTypeTask.id = option.value == null ? 0 : option.value;
      this.currentTypeTask.text = option.value ? "Tasks" : text + " Tasks";
      if (option) {
        this.filters.status_done = option.value;
      } else {
        this.filters.status_done = null;
      }
    },
    selectedYear(year) {
      this.filters.year = year;
    },
    openListCompletedTasks() {
      this.listCompletedTasksController = true;
    },
    closeListCompletedTasks() {
      this.listCompletedTasksController = false;
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
#task--customer--service {
  #container--task-general {
    display: flex;
    flex-direction: column;
    .child {
      padding: 10px 5px;
    }
    #container--task {
      height: 400px;
      overflow: auto;
    }
    #actions--task {
      display: flex;
      justify-content: flex-end;
      .new--task {
        background-color: #3f7afa !important;
        border-color: #3f7afa !important;
        color: #ffffff !important;
      }
      .first--note {
        background-color: #ff9900 !important;
        border-color: #ff9900 !important;
        color: #ffffff !important;
      }
    }
  }
  .font-size-md {
    font-size: 13px !important;
  }
  .text--through {
    text-decoration: line-through;
  }
  .custom-span {
    position: absolute !important;
    font-size: 25px !important;
    font-weight: bolder !important;
    color: dark !important;
  }
  .custom-spinner {
    position: absolute !important;
  }
}
</style>
