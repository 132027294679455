<template>
  <div id="notes--customer--service">
    <div id="container--notes-general">
      <div id="filters--notes" class="child">
        <b-row>
          <b-col>
            <FiltersYearNotes @selectedYear="(year) => (yearSelect = year)" />
          </b-col>
          <b-col></b-col>
          <b-col>
            <SelectCustom
              :options="optionsSelect"
              placeholder="Type of note"
              @selected="selectedTypeOfNote"
            />
          </b-col>
        </b-row>
      </div>
      <div id="container--notes" class="child">
        <div
          v-for="(month, index) in months"
          :key="index"
          class="note--months"
          :style="{
            background:
              monthSelect === month.id
                ? '#3F7AFA'
                : isDarkSkin
                ? '#00226B80'
                : '#95B6FF',
          }"
          @click="openModalAllNotes(month.id)"
        >
          <div class="item">
            <span class="month--text">{{ month.name }}</span>
          </div>
          <div class="item">
            <span class="total">
              {{ getNoteByMonth(month) }}
            </span>
          </div>
        </div>
      </div>
      <div id="actions--notes" class="child">
        <b-button
          size="md"
          class="new--note text-uppercase"
          v-if="!onlyRead"
          :disabled="isResponsibleAccount"
          @click="openAddNotesModal"
        >
          <feather-icon icon="PlusIcon" />
          <span> Add Note</span>
        </b-button>
        <b-button
          size="md"
          class="first--note text-uppercase"
          @click="openFirstNoteModal"
        >
          <feather-icon icon="FileIcon" />
          <span> First Note</span>
        </b-button>
        <b-button
          v-if="
            startDate >= '2020-12-05' &&
            (moduleId === 6 || client.program_id == 3)
          "
          :variant="statusWel == 1 ? 'warning' : 'secondary'"
          class="ml-custom"
          :disabled="isResponsibleAccount"
          @click="openNotesWelcomeCe()"
        >
          <tabler-icon
            icon="FileInvoiceIcon"
            class="mr-half"
          />WELCOME</b-button
        >
        <b-button
          v-if="
            startDate >= '2021-01-05' &&
            (moduleId === 7 || client.program_id == 2)
          "
          :variant="statusWel == 1 ? 'warning' : 'secondary'"
          class="ml-custom"
          :disabled="isResponsibleAccount"
          @click="openNotesWelcomeBc()"
        >
          <tabler-icon
            icon="FileInvoiceIcon"
            class="mr-half"
          />WELCOME</b-button
        >
        <b-button
          v-if="startDate >= '2020-12-05' && moduleId === 6 && statusWel == 0"
          variant="secondary"
          class="ml-custom"
          :disabled="isResponsibleAccount"
          @click="openNotesWelcomeBc()"
        >
          <tabler-icon
            icon="FileInvoiceIcon"
            class="mr-half"
          />WELCOME</b-button
        >
        <b-button
          v-if="
            startDate >= '2021-01-08' &&
            (moduleId === 5 || client.program_id == 4)
          "
          :variant="statusWel == 1 ? 'warning' : 'secondary'"
          class="ml-custom"
          :disabled="isResponsibleAccount"
          @click="openNotesWelcomeDS()"
        >
          <tabler-icon
            icon="FileInvoiceIcon"
            class="mr-half"
          />WELCOME</b-button
        >
        <b-button
          v-if="startDate >= '2021-01-08' && moduleId === 20"
          :variant="statusWel == 1 ? 'warning' : 'secondary'"
          class="ml-custom"
          :disabled="isResponsibleAccount"
          @click="openNotesWelcomeCe()"
        >
          <tabler-icon icon="FileInvoiceIcon" class="mr-half" />
          WELCOME
        </b-button>
        <b-button
          v-if="
            client.start_date >= '2020-17-08' &&
            client.s_welcome == 1 &&
            moduleId == 22
          "
          variant="warning"
          style="width: 160px !important; margin-left: 5px; margin-right: 5px"
          @click="openNotesWelcomeCSD"
        >
          <tabler-icon
            icon="FileInvoiceIcon"
            style="margin-right: 0.5rem"
          />WELCOME</b-button
        >
        <b-button
          v-if="
            client.start_date >= '2020-17-08' &&
            client.s_welcome == 0 &&
            moduleId == 22
          "
          variant="secondary"
          style="width: 160px !important; margin-left: 5px; margin-right: 5px"
          class="ml-1"
          @click="openNotesWelcomeCSD"
          ><tabler-icon
            icon="FileInvoiceIcon"
            style="margin-right: 0.5rem"
          />WELCOME</b-button
        >
        <b-button
          v-if="idNotes != null"
          @click="showSummaryModal = true"
          variant="info"
        >
          <feather-icon icon="PhoneIcon" />
          SALE CALL
        </b-button>
      </div>
    </div>
    <AddNotesModal
      v-if="addNotesModalController"
      v-bind="modalNoteScope.attributes"
      v-on="modalNoteScope.events"
    />
    <ListAllNotesModal
      v-if="openModalAllNotesController"
      v-bind="modalAllNotesScope.attributes"
      v-on="modalAllNotesScope.events"
    />
    <ModalNotasCredit
      v-if="creditExpertsDigitalController"
      v-bind="modalNotasCreditScope.attributes"
      v-on="modalNotasCreditScope.events"
    />
    <ModalViewApplications
      v-if="openModalAppsController"
      @close="openModalAppsController = false"
    />
    <ModalAddWorkPlan
      v-if="workPlanController"
      v-bind="modalAddWorkPlanScope.attributes"
      v-on="modalAddWorkPlanScope.events"
    />
    <ActionDoneReturnModal
      v-if="showDoneReturnModal"
      v-bind="modalActionsReturnModalScope.attributes"
      v-on="modalActionsReturnModalScope.events"
    />
    <modal-notes-boost
      v-if="boostCreditController"
      :note-info="noteInfo"
      @hide="closeBoostCreditModal"
    />
    <notes-welcome-bc-modal
      v-if="notesWelcomeBcModalController"
      :client="client"
      @close="closeNotesWelcomeBc"
      @reload="getStatusWelOnChange"
    />
    <notes-welcome-ce-modal
      v-if="notesWelcomeCeModalController"
      :client="client"
      @close="closeNotesWelcomeCe"
      @reload="getStatusWelOnChange"
    />
    <notes-welcome-ds-modal
      v-if="notesWelcomeDsModalController"
      :client="client"
      @close="closeNotesWelcomeDS"
      @reload="getStatusWelOnChange"
    />
    <notes-welcome-customer-service-dig-modal
      v-if="notesWelcomeCustomerServiceModalController"
      :id-work-plan="idWorkPlanForWelcome"
      :client="client"
      @close="closeNotesWelcomeCSD"
      @reload="getStatusWelOnChange"
    />
    <modal-notes-all
      v-if="firstNoteController"
      :note-info="noteInfo"
      :is-from-task-notes="true"
      @hide="closeFirstNoteModal"
    />
    <ModalNotesTax
      v-if="openModalNotesTaxController"
      :note-info="noteInfo"
      @hide="closeModalNotesTax"
    />
    <modal-notes-credit-new
      v-if="openModalNotesNewCe"
      :note-info="noteInfo"
      @hide="closeNotesNewCredit"
    />
    <ModalNotesFirst
      v-if="isActiveModalBusinessNotes"
      :note-info="noteInfo"
      @hide="closeModalBusinessNotesAll"
    />
    <modal-notes-specialist
      v-if="openModalNotesSpecialist"
      :note-info="noteInfo"
      @hide="closeModalNotesSpecialist"
    />
    <CallSummaryModal
      v-if="showSummaryModal"
      :idNotes="idNotes"
      :onlyView="true"
      @closeModal="showSummaryModal = false"
    />
  </div>
</template>
<script>
import FiltersYearNotes from "@/views/ce-digital/sub-modules/customer-service/views/clients/dashboard/options/task-notes-module/components/FiltersYearNotes.vue";
import SelectCustom from "@/views/ce-digital/sub-modules/customer-service/views/clients/dashboard/options/task-notes-module/components/SelectCustom.vue";
import AddNotesModal from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/AddNotesModal.vue";
import moment from "moment";
import clientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import RequestWorkplanService from "@/views/commons/components/request-workplan/services/request-workplan.service.js";
import ListAllNotesModal from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/ListAllNotesModal.vue";
import ModalNotasCredit from "@/views/commons/components/first-notes/ModalNotasCredit.vue";
import ModalNotesBoost from "@/views/commons/components/first-notes/ModalNotesBoost.vue";
import ModalViewApplications from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/ModalViewApplications.vue";
import ModalAddWorkPlan from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/ModalAddWorkPlan.vue";
import ActionDoneReturnModal from "@/views/commons/components/request-workplan/views/components/modal/ActionDoneReturnModal.vue";
import NotesWelcomeBcModal from "@/views/boost-credit/views/clients/dashboard/options/task-notes-module/NotesWelcomeBcModal.vue";
import NotesWelcomeCeModal from "@/views/credit-experts/views/clients/dashboard/options/task-notes-module/NotesWelcomeCeModal.vue";
import NotesWelcomeDsModal from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/NotesWelcomeDsModal.vue";
import NotesWelcomeCustomerServiceDigModal from "@/views/ce-digital/sub-modules/customer-service/views/clients/dashboard/options/task-notes-module/NotesWelcomeCustomerServiceDigModal.vue";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import ModalNotesAll from "@/views/commons/components/first-notes/ModalNotesAll.vue";
import ModalNotesTax from "@/views/commons/components/first-notes/ModalNotesTax.vue";
import ModalNotesCreditNew from "@/views/commons/components/first-notes/credit-experts/ModalNotesCreditNew.vue";
import ModalNotesFirst from "@/views/commons/components/first-notes/ModalNotesFirst.vue";
import ModalNotesSpecialist from "@/views/commons/components/first-notes/ModalNotesSpecialist.vue";
import CallSummaryModal from "@/views/crm/views/sales-made/components/modals/CallSummaryModal.vue";

export default {
  name: "Notes",
  components: {
    FiltersYearNotes,
    SelectCustom,
    AddNotesModal,
    ListAllNotesModal,
    ModalNotasCredit,
    ModalViewApplications,
    ModalAddWorkPlan,
    ActionDoneReturnModal,
    ModalNotesBoost,
    NotesWelcomeBcModal,
    NotesWelcomeCustomerServiceDigModal,
    NotesWelcomeCeModal,
    NotesWelcomeDsModal,
    ModalNotesAll,
    ModalNotesTax,
    ModalNotesCreditNew,
    ModalNotesFirst,
    ModalNotesSpecialist,
    CallSummaryModal,
  },
  props: {
    currentUser: {
      required: true,
    },
    client: {
      required: true,
    },
    notes: {
      required: false,
    },
    moduleId: {
      required: true,
    },
  },
  data() {
    return {
      notesWelcomeBcModalController: false,
      optionsSelect: [
        { text: "All notes", value: null },
        { text: "Commentary", value: "COMMENTARY" },
        { text: "Email", value: "EMAIL" },
        { text: "SMS", value: "SMS", icon: "MessageCircleIcon" },
        { text: "Conversation", value: "CONVERSATION" },
      ],
      months: [],
      yearSelect: 0,
      monthSelect: 0,
      typeAccesses: [1, 2, 3, 4, 5, 6, 7, 11],
      typeAccessPermanent: null,
      addNotesModalController: false,
      isLastNote: 1,
      typeOfNote: null,
      arrworks: [],
      workPlan: {
        idWorkPlan: 0,
      },
      showDoneReturnModal: false,
      openModalAppsController: false,
      workPlanController: false,
      openModalAllNotesController: false,
      creditExpertsDigitalController: false,
      modalMonths: {
        monthToSend: null,
      },
      firstNoteController: false,
      boostCreditController: false,
      dataWp: null,
      notesWelcomeCustomerServiceModalController: false,
      idWorkPlanForWelcome: null,
      notesWelcomeCeModalController: false,
      notesWelcomeDsModalController: false,
      openModalNotesTaxController: false,
      openModalNotesNewCe: false,
      isActiveModalBusinessNotes: false,
      openModalNotesSpecialist: false,
      showSummaryModal: false,
      idNotes: null,
    };
  },
  computed: {
    modalActionsReturnModalScope() {
      return {
        attributes: {
          dataClient: this.dataWp,
          revision: false,
        },
        events: {
          close: () => {
            this.showDoneReturnModal = false;
          },
        },
      };
    },
    modalAddWorkPlanScope() {
      return {
        attributes: {
          "id-work-plan": this.workPlan.idWorkPlan,
          account: this.client.account,
          "client-name": this.client.client_name,
        },
        events: {
          reload: this.getWhenWorkPlanIsCreated,
          close: this.closeWorkPlanModal,
        },
      };
    },
    modalNotasCreditScope() {
      return {
        attributes: {
          "note-info": {
            nameProgram: this.client.program_name,
            created: this.client.creates,
            nameClient: this.client.client_name,
            saleId: this.client.sale_id,
            sourcesName: this.client.sourcesname,
            pack: this.client.pack,
            type: 1,
            editModal: false,
            statusSale: 4,
            notSeller: false,
          },
          "from-dashboard": true,
        },
        events: {
          hide: () => {
            this.creditExpertsDigitalController = false;
          },
        },
      };
    },
    modalAllNotesScope() {
      return {
        attributes: {
          month: this.monthSelect,
          year: this.yearSelect,
          "name-client": this.client.client_name,
        },
        events: {
          close: () => {
            this.openModalAllNotesController = false;
          },
        },
      };
    },
    modalNoteScope() {
      return {
        attributes: {
          "name-client": this.client.client_name,
          editing: false,
          "from-credit-experts-digital": true,
          "type-access": this.typeAccessPermanent,
          isLastNote: this.isLastNote,
        },
        events: {
          close: this.closeAddNotesModal,
          reload: this.getNotesPerYear,
        },
      };
    },
    onlyRead() {
      return this.moduleId === 18;
    },
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
    startDate() {
      return this.client.start_date;
    },
    statusWel() {
      return this.client.s_welcome;
    },
    statewp() {
      return this.client.total_wp;
    },
    statewp2() {
      return this.client.total_wp2;
    },
    noteInfo() {
      return {
        nameProgram: this.client.program_name,
        created: this.client.creates,
        nameClient: this.client.client_name,
        type: 1,
        editModal: false,
        statusSale: 4,
        notSeller: false,
        saleId: this.client.sale_id,
        sourcesName: this.client.sourcesname,
        pack: this.client.pack,
        capturedName: this.client.captured_name,
        sellerName: this.client.seller_name,
        module: this.client.module,
        event_id: this.client.event_id,
      };
    },
  },
  watch: {
    async yearSelect() {
      this.getNotesPerYear();
    },
    async typeOfNote(value) {
      await this.getNotesPerYear();
    },
  },
  async created() {
    this.typeAccessPermanent = this.typeAccesses;
    this.setMonthsName();
    try {
      this.addPreloader();
      await this.getAllWorks();
      await this.getNoteIds();
    } catch (error) {
      console.log(error);
    } finally {
      this.removePreloader();
    }
  },
  methods: {
    closeModalNotesSpecialist() {
      this.openModalNotesSpecialist = false;
    },
    closeModalBusinessNotesAll() {
      this.isActiveModalBusinessNotes = false;
    },
    closeNotesNewCredit() {
      this.openModalNotesNewCe = false;
    },
    closeModalNotesTax() {
      this.openModalNotesTaxController = false;
    },
    closeFirstNoteModal() {
      this.firstNoteController = false;
    },
    openNotesWelcomeDS() {
      this.notesWelcomeDsModalController = true;
    },
    closeNotesWelcomeDS() {
      this.notesWelcomeDsModalController = false;
    },
    openNotesWelcomeCe() {
      this.notesWelcomeCeModalController = true;
    },
    closeNotesWelcomeCe() {
      this.notesWelcomeCeModalController = false;
    },
    async getStatusWelOnChange() {
      await Promise.all([
        this.$store.dispatch("DebtSolutionClients/A_GET_CLIENTS", {
          id: this.$route.params.idClient,
        }),
      ]);
    },
    openNotesWelcomeBc() {
      this.notesWelcomeBcModalController = true;
    },
    closeNotesWelcomeBc() {
      this.notesWelcomeBcModalController = false;
    },
    closeBoostCreditModal() {
      this.boostCreditController = false;
    },
    async openAddNotesModal() {
      if (
        this.typeAccesses.includes(this.typeAccessPermanent) &&
        this.moduleId === 22 &&
        this.isAgent
      ) {
        const response = await this.showConfirmSwal(
          "What are you going to do?",
          "",
          {
            confirmButtonText: "Just create a note",
            showDenyButton: true,
            showCancelButton: false,
            denyButtonText: "Create a note and leave",
            customClass: {
              confirmButton: "btn btn-primary mr-1",
              denyButton: "btn btn-info mr-1",
            },
          }
        );
        this.isLastNote = 1;
        if (response.isConfirmed) {
          this.isLastNote = 0;
          this.typeAccessPermanent = null;
        }
        if (response.isDismissed) {
          return;
        }
      }
      this.addNotesModalController = true;
    },
    closeAddNotesModal() {
      this.addNotesModalController = false;
      this.typeAccessPermanent = this.typeAccess;
    },
    async getNotesPerYear() {
      await this.$store.dispatch("DebtSolutionClients/A_NOTES", {
        id: this.$route.params.idClient,
        year: this.yearSelect,
        typeOfNote: this.typeOfNote,
      });
    },
    async getNoteIds() {
      const params = {
        ca_id: this.$route.params.idClient,
      };
      try {
        const { data } = await ClientsOptionsServices.getNoteIds(params);
        this.idNotes = data.id_notes;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    setMonthsName() {
      this.months = [];
      for (let i = 0; i < 12; i++) {
        const nameMonth = moment().month(i);
        this.months.push({
          id: i + 1,
          name: nameMonth.format("MMMM"),
          abr: nameMonth.format("MMM").toLowerCase(),
        });
      }
    },
    getNoteByMonth(month) {
      let total = 0;
      if (month.abr === "aug") {
        total = this.notes.agu;
      }
      if (month.abr === "sep") {
        total = this.notes.set;
      }
      return this.notes[month.abr] ? this.notes[month.abr] : total;
    },
    selectedTypeOfNote(option) {
      if (option) {
        this.typeOfNote = option.value;
      } else {
        this.typeOfNote = null;
      }
    },
    async getAllWorks() {
      try {
        const data = await clientsOptionsServices.getAllWorksNew({
          id: this.$route.params.idClient,
        });
        if (data.status === 200) {
          this.arrworks = data.data;
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    closeWorkPlanModal() {
      this.idWorkPlan = "";
      this.workPlanController = false;
    },
    openModalApps() {
      this.openModalAppsController = true;
    },
    openModalAllNotes(monthNumber) {
      this.monthSelect = monthNumber;
      this.openModalAllNotesController = true;
    },
    openFirstNoteModal() {
      if (
        (this.moduleId === 7 || this.client.program_id === 2) &&
        this.client.start_date >= "2020-10-30"
      ) {
        this.boostCreditController = true;
      } else if (this.moduleId === 8 || this.client.program_id === 5) {
        this.openModalNotesTaxController = true;
      } else if (
        (this.moduleId === 22 || this.client.program_id === 3) &&
        this.client.start_date >= "2020-10-30"
      ) {
        this.creditExpertsDigitalController = true;
      } else if (this.moduleId === 6) {
        this.openModalNotesNewCe = true;
      } else if (this.client.program_id === 1) {
        this.isActiveModalBusinessNotes = true;
      } else if (this.client.program_id === 7) {
        this.openModalNotesSpecialist = true;
      } else {
        this.firstNoteController = true;
      }
    },
    async getWhenWorkPlanIsCreated() {
      await this.getAllWorks();
    },
    openNotesWelcomeCSD() {
      this.notesWelcomeCustomerServiceModalController = true;
    },
    closeNotesWelcomeCSD() {
      this.notesWelcomeCustomerServiceModalController = false;
    },
    async getStatusWelOnChange() {
      await this.$store.dispatch("DebtSolutionClients/A_SET_STATUS_WEL", 1);
      this.status_wel = this.client.s_welcome;
    },
    async getFirstWorkPlan() {
      try {
        const data = await ClientsOptionsServices.getFirstWorkPlan({
          idAccount: this.$route.params.idClient,
        });
        if (data.status === 200) {
          if (data.data.length > 0) {
            this.idWorkPlanForWelcome = data.data[0].id;
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#notes--customer--service {
  #container--notes-general {
    display: flex;
    flex-direction: column;
    .child {
      padding: 10px 5px;
    }
    #container--notes {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      height: 400px;
      .note--months {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: calc(50% - 27px);
        // background: rgb(149, 182, 255);
        border-radius: 50px;
        margin: 10px;
        @media (min-width: 768px) {
          width: calc(33.33% - 27px);
        }
        @media (min-width: 1200px) {
          width: calc(16.66% - 27px);
        }
        .item {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;
          font-weight: 900;
          .month--text {
            font-size: 1.1rem;
            margin-top: 25px;
          }
          .total {
            display: flex;
            justify-content: center;
            align-items: center;
            background: #ffffff;
            width: 75px;
            height: 75px;
            border-radius: 50%;
            color: black;
            font-size: 2.25rem;
            transition: all 0.5s ease;
          }
        }
        .item:last-child {
          padding-bottom: 10px;
        }
      }
      .note--months.active {
        // background-color: rgb(63, 122, 250);
        transition: background-color 0.5s ease, border 0.5s ease;
        .total {
          color: rgb(63, 122, 250);
        }
      }
      .note--months:hover {
        cursor: pointer;
      }
    }
    #actions--notes {
      display: flex;
      justify-content: flex-start;
      gap: 0 12px;
      .new--note {
        background-color: #3f7afa !important;
        border-color: #3f7afa !important;
        color: #ffffff !important;
      }
      .first--note {
        background-color: #ff9900 !important;
        border-color: #ff9900 !important;
        color: #ffffff !important;
      }
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
  }
}
</style>
