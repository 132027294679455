<template>
  <div class="container--filter-year">
    <div
      class="main"
      :style="{ background: isDarkSkin ? '#131323' : '#EDF1F8' }"
    >
      <div class="icon" @click="previousYear">
        <feather-icon icon="ChevronLeftIcon" size="20" />
      </div>
      <div class="year" @click="openYears">
        <span> {{ yearSelected }} </span>
      </div>
      <div class="icon" @click="nextYear">
        <feather-icon icon="ChevronRightIcon" size="20" />
      </div>
    </div>
    <div class="list--years" v-if="open">
      <div class="card--year">
        <div class="card--year-title">YEAR</div>
        <div class="card--year-items">
          <div
            class="items"
            v-for="(year, index) in yearOptions"
            :key="index"
            :class="
              yearSelectedOnStandby === year.value ? 'selected--year' : ''
            "
            @click="selected(year.value)"
          >
            {{ year.text }}
          </div>
        </div>
        <div class="card--year--actions">
          <b-button class="btn--apply" @click="applyYear">Apply</b-button>
          <b-button class="btn--cancel" @click="closeYears">Cancel</b-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "FiltersYearNotes",
  data() {
    return {
      yearOptions: [],
      yearSelected: 0,
      open: false,
      yearSelectedOnStandby: 0,
    };
  },
  methods: {
    listYears() {
      this.yearSelected = Number(moment().format("YYYY"));
      for (let x = 2014; x <= moment().format("YYYY"); x++) {
        this.yearOptions.push({ value: x, text: x });
      }
      this.sendYear(this.yearSelected);
    },
    selected(year) {
      this.yearSelectedOnStandby = year;
    },
    previousYear() {
      let yearDefault = 2014;
      if (this.yearSelected > yearDefault) {
        this.yearSelected--;
        this.sendYear(this.yearSelected);
      }
    },
    nextYear() {
      let currentYear = Number(moment().format("YYYY"));
      if (this.yearSelected < currentYear) {
        this.yearSelected++;
        this.sendYear(this.yearSelected);
      }
    },
    openYears() {
      this.open = true;
    },
    closeYears() {
      this.open = false;
      this.yearSelectedOnStandby = this.yearSelected;
    },
    applyYear() {
      this.yearSelected = this.yearSelectedOnStandby;
      this.sendYear(this.yearSelected);
      this.closeYears();
    },
    closeOnClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.closeYears();
      }
    },
    sendYear(year) {
      this.$emit("selectedYear", year);
    },
  },
  created() {
    this.listYears();
  },
  mounted() {
    document.addEventListener("click", this.closeOnClickOutside);
  },
  destroyed() {
    document.removeEventListener("click", this.closeOnClickOutside);
  },
};
</script>
<style lang="scss" scoped>
.container--filter-year {
  position: relative;
  .main {
    display: flex;
    justify-content: space-between;
    // background: rgb(237, 241, 248);
    padding: 10px 5px;
    font-weight: bold;
    border-radius: 5px;
    .year span {
      text-decoration: underline;
      cursor: pointer;
    }
    .icon {
      cursor: pointer;
    }
  }
  .list--years {
    color: #000;
    position: absolute;
    top: 104%;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2), 0px 5px 20px rgba(0, 0, 0, 0.1);
    left: 0;
    right: -80%;
    border-radius: 10px;
    transition: display 0.5s ease, opacity 0.5s ease;
    z-index: 500;
    .card--year-title {
      padding: 20px 0px;
      text-align: center;
      border-bottom: 1px solid #f1f1f1;
    }
    .card--year-items {
      justify-content: space-between;
      display: flex;
      padding: 10px 15px;
      flex-wrap: wrap;
      .items {
        margin-bottom: 8px;
        width: calc(50% - 10px);
        border: 1px solid #e6e6e6;
        border-radius: 5px;
        padding: 10px 5px;
        text-align: center;
        cursor: pointer;
        @media (min-width: 768px) {
          width: calc(25% - 10px);
        }
      }
      .items.selected--year {
        border: 1px solid rgb(75, 130, 250);
        color: rgb(75, 130, 250);
        transition: background-color 0.5s ease, border 0.5s ease;
      }
    }
    .card--year--actions {
      display: flex;
      justify-content: end;
      padding: 10px 15px;
      border-top: 1px solid #f1f1f1;
      button {
        font-weight: bold;
        margin-inline-start: 5px;
      }
      .btn--apply {
        background: #3f7afa !important;
        border-color: #3f7afa !important;
      }
      .btn--cancel {
        background-color: #e7f1ff !important;
        border-color: #e7f1ff !important;
        color: #6997fb !important;
      }
    }
  }
}
</style>
