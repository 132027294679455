<template>
  <b-modal
    v-model="onControl"
    title="WELCOME"
    title-tag="h3"
    title-class="h3 text-white font-weight-bolder"
    header-bg-variant="transparent"
    modal-class="custom-modal-amg"
    header-class="p-0"
    size="xmd"
    scrollable
    no-close-on-backdrop
    @hidden="close"
  >
    <template v-slot:modal-header>
      <div class="header-modal d-flex justify-content-between">
        <h3 class="text-white m-0 font-weight-bold">
          Welcome
        </h3>
        <div>
          <feather-icon
            icon="XIcon"
            size="22"
            class="close-button"
            @click="close"
          />
        </div>
      </div>
    </template>
    <validation-observer ref="form">
      <b-card
        header="1) PERSONAL INFORMATION"
        :header-bg-variant="disabled ? 'primary' : ''"
        border-variant="primary"
        :header-text-variant="disabled ? '' : 'primary'"
        header-class="font-weight-bolder text-white py-1"
      >
        <div class="mt-2">
          <b-col>
            <b-row>
              <b-col cols="2">
                <span>DBA</span>
              </b-col>
              <b-col cols="3">
                <b-form-input
                  v-model="welcome.client_first_name"
                  :disabled="dbaedit"
                />
              </b-col>
              <b-col cols="3">
                <b-form-input
                  v-model="welcome.client_middle_name"
                  :disabled="dbaedit"
                />
              </b-col>
              <b-col cols="3">
                <b-form-input
                  v-model="welcome.client_last_name"
                  :disabled="dbaedit"
                />
              </b-col>
              <b-col cols="1" class="d-flex align-items-center">
                <validation-provider
                  v-slot="{ errors }"
                  name="dba"
                  rules="truthy"
                >
                  <b-form-checkbox
                    v-model="checkDba"
                    :disabled="disabled"
                    :state="errors[0] && validator ? false : null"
                    @change="editdba"
                  />
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="2">
                <span>ADDRESS</span>
              </b-col>
              <b-col>
                <vue-google-autocomplete
                  id="welcome_location"
                  v-model="welcome.client_street"
                  classname="input-form form-control fond-white"
                  placeholder="Please type your address"
                  :country="['us']"
                  style="height: 35px !important"
                  :readonly="adressedit"
                  @placechanged="getAddressData"
                />
              </b-col>
              <b-col cols="1" class="d-flex align-items-center">
                <validation-provider
                  v-slot="{ errors }"
                  name="address"
                  rules="truthy"
                >
                  <b-form-checkbox
                    v-model="checkAddress"
                    :disabled="disabled"
                    :state="errors[0] && validator ? false : null"
                    @change="editadress"
                  />
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col cols="2" />
              <b-col cols="3">
                <b-form-input
                  v-model="welcome.client_city"
                  :disabled="adressedit"
                />
              </b-col>
              <b-col cols="3">
                <b-form-select
                  v-model="welcome.client_states_eeuu_slug"
                  :options="states"
                  text-field="state"
                  value-field="slug"
                  :disabled="adressedit"
                />
              </b-col>
              <b-col cols="3">
                <b-form-input
                  v-model="welcome.client_zipcode"
                  :disabled="adressedit"
                />
              </b-col>
              <b-col cols="1" />
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <b-row>
                  <b-col cols="4">
                    <span>SSN</span>
                  </b-col>
                  <b-col cols="6">
                    <b-form-input v-model="welcome.client_ssn" disabled />
                  </b-col>
                  <b-col v-if="welcome.l_itin !== ''" class="mt-1" cols="4">
                    <span>ITIN</span>
                  </b-col>
                  <b-col v-if="welcome.l_itin !== ''" class="mt-1" cols="6">
                    <b-form-input v-model="welcome.l_itin" disabled />
                  </b-col>
                  <b-col v-if="welcome.l_other !== ''" class="mt-1" cols="4">
                    <span>CPN</span>
                  </b-col>
                  <b-col v-if="welcome.l_other !== ''" class="mt-1" cols="6">
                    <b-form-input v-model="welcome.l_other" disabled />
                  </b-col>
                  <b-col cols="2" class="d-flex align-items-center">
                    <validation-provider
                      v-slot="{ errors }"
                      name="ssn"
                      rules="truthy"
                    >
                      <b-form-checkbox
                        v-model="checkPersonSsn"
                        :disabled="disabled"
                        :state="errors[0] && validator ? false : null"
                        @change="editssn"
                      />
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
              <b-col>
                <b-row>
                  <b-col cols="4">
                    <span>DOB</span>
                  </b-col>
                  <b-col cols="6">
                    <kendo-datepicker
                      v-model="welcome.client_dob"
                      v-mask="'##/##/####'"
                      class="kk-picker-wrap form-control"
                      :class="[
                        'w-100 bg-transparent k-picker-custom',
                        { 'text-white': isDarkSkin },
                      ]"
                      :format="'MM/dd/yyyy'"
                      :disabled="!!dobedit"
                      readonly
                    />
                  </b-col>
                  <b-col cols="2" class="d-flex align-items-center">
                    <validation-provider
                      v-slot="{ errors }"
                      name="dob"
                      rules="truthy"
                    >
                      <b-form-checkbox
                        v-model="checkPersonDob"
                        :disabled="disabled"
                        :state="errors[0] && validator ? false : null"
                        @change="editdob"
                      />
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <b-row>
                  <b-col cols="4">
                    <span>LEGAL STATUS</span>
                  </b-col>
                  <b-col cols="6">
                    <b-form-select
                      v-model="welcome.client_status_l"
                      :options="legalStatuses"
                      :disabled="statusedit"
                    />
                  </b-col>
                  <b-col cols="2" class="d-flex align-items-center">
                    <validation-provider
                      v-slot="{ errors }"
                      name="legal_status"
                      rules="truthy"
                    >
                      <b-form-checkbox
                        v-model="checkLegal"
                        :disabled="disabled"
                        :state="errors[0] && validator ? false : null"
                        @change="editstatus"
                      />
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
              <b-col>
                <b-row>
                  <b-col cols="4">
                    <span>EMAIL</span>
                  </b-col>
                  <b-col cols="6">
                    <b-form-input
                      v-model="welcome.client_email"
                      :disabled="emailedit"
                    />
                  </b-col>
                  <b-col cols="2" class="d-flex align-items-center">
                    <validation-provider
                      v-slot="{ errors }"
                      name="email"
                      rules="truthy"
                    >
                      <b-form-checkbox
                        v-model="checkEmail"
                        :disabled="disabled"
                        :state="errors[0] && validator ? false : null"
                        @change="editemail"
                      />
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <hr />
            <b-row class="d-flex justify-content-between mt-2">
              <div style="width: 50%" />
              <div
                style="width: 50%; padding-right: 4.3rem"
                class="d-flex justify-content-between"
              >
                <span class="font-weight-bolder">PAYMENT</span>
                <validation-provider
                  v-slot="{ errors }"
                  name="payment"
                  rules="truthy"
                >
                  <b-form-checkbox
                    v-model="checkPersoAp"
                    :disabled="disabled"
                    :state="errors[0] && validator ? false : null"
                    @change="editcard"
                  />
                </validation-provider>
              </div>
            </b-row>
            <b-row v-if="welcome.cf_type_payment === 0" class="mt-1">
              <b-col>
                <b-row>
                  <span>METHOD</span>
                  <h4 class="ml-2 d-inline">
                    <b-badge
                      style="color: #6e6b7b; padding: 0.7rem; font-size: 14px"
                      variant="light"
                    >
                      Credit Card
                    </b-badge>
                  </h4>
                </b-row>
              </b-col>
              <b-col>
                <b-row>
                  <span>TYPE</span>
                  <h4 class="ml-2 d-inline">
                    <b-badge
                      style="color: #6e6b7b; padding: 0.7rem; font-size: 14px"
                      variant="light"
                    >
                      Automatic
                    </b-badge>
                  </h4>
                </b-row>
              </b-col>
              <b-col>
                <b-row>
                  <span>DATE</span>
                  <h4 class="ml-2 d-inline">
                    <b-badge
                      style="color: #6e6b7b; padding: 0.7rem; font-size: 14px"
                      variant="light"
                    >
                      {{ diames }}
                    </b-badge>
                  </h4>
                </b-row>
              </b-col>
              <monthly-payment-fee-component
                style="width: 40%"
                :welcome="welcome"
              />
            </b-row>
            <div
              v-if="welcome.cf_type_payment === 1"
              class="mt-1 d-flex justify-content-between"
            >
              <div>
                <span>METHOD</span>
                <h4 class="ml-2 d-inline">
                  <b-badge
                    style="color: #6e6b7b; padding: 0.7rem; font-size: 14px"
                    variant="light"
                  >
                    Credit card
                  </b-badge>
                </h4>
              </div>
              <div>
                <span>TYPE</span>
                <h4 class="ml-2 d-inline">
                  <b-badge
                    style="color: #6e6b7b; padding: 0.7rem; font-size: 14px"
                    variant="light"
                  >
                    Manual
                  </b-badge>
                </h4>
              </div>
              <monthly-payment-fee-component
                style="width: 50%"
                :welcome="welcome"
              />
            </div>
            <b-row v-if="welcome.cf_type_payment === 2" class="mt-1">
              <b-col>
                <b-row>
                  <span>METHOD</span>
                  <h4 class="ml-2 d-inline">
                    <b-badge
                      style="color: #6e6b7b; padding: 0.7rem; font-size: 14px"
                      variant="light"
                    >
                      Others
                    </b-badge>
                  </h4>
                </b-row>
              </b-col>
              <monthly-payment-fee-component
                style="width: 60%"
                :welcome="welcome"
              />
            </b-row>
            <hr />
            <b-row class="d-flex justify-content-center mt-2">
              <span class="font-weight-bolder">CREDIT CARDS</span>
              <b-table :fields="fieldsCreditCard" :items="cards" class="mt-1">
                <template #cell(cvc)>
                  <span>XXX</span>
                </template>
              </b-table>
            </b-row>
          </b-col>
        </div>
      </b-card>
      <b-card
        header="2) RECOMMENDATIONS"
        :header-bg-variant="disabled ? 'primary' : ''"
        border-variant="primary"
        :header-text-variant="disabled ? '' : 'primary'"
        header-class="font-weight-bolder text-white py-1"
      >
        <template #header>
          <div>
            <span>2) RECOMMENDATIONS</span>
            <span
              v-if="idWorkPlanForWelcome == null"
              class="text-danger ml-1 font-small-3"
              >(There are no recommendations yet)</span
            >
          </div>
        </template>
        <b-card-body>
          <validation-provider name="welcome_work_plan" rules="required">
            <b-form-input v-model="idWorkPlanForWelcome" class="d-none" />
          </validation-provider>
          <work-plan-inputs
            :client-name="client.client_name"
            :id-work-plan="idWorkPlanForWelcome"
            :account="client.account"
          />
        </b-card-body>
      </b-card>
      <b-card
        header="3) FILES"
        :header-bg-variant="disabled ? 'primary' : ''"
        border-variant="primary"
        :header-text-variant="disabled ? '' : 'primary'"
        header-class="font-weight-bolder text-white py-1"
      >
        <files-simple-table />
      </b-card>
      <b-card
        :header-bg-variant="disabled || checkDoubts ? 'primary' : ''"
        border-variant="primary"
        :header-text-variant="disabled || checkDoubts ? '' : 'primary'"
        header-class="font-weight-bolder text-white py-1"
        no-body
      >
        <template #header>
          <span>4) DOUBTS OR QUESTION</span>
          <validation-provider v-slot="{ errors }" name="doubts" rules="truthy">
            <b-form-checkbox
              v-model="checkDoubts"
              :disabled="disabled"
              :state="errors[0] && validator ? false : null"
              @change="editDoubts"
            />
          </validation-provider>
        </template>
        <b-card-body class="mt-2">
          <b-form-textarea v-model="welcome.w_doubts" :disabled="doubtsedit" />
        </b-card-body>
      </b-card>
    </validation-observer>
    <template #modal-footer>
      <b-button
        v-if="welcome.statuswelcome !== 1"
        variant="success"
        @click="saveUpdateWelcome"
      >
        DONE
      </b-button>
      <b-form-group v-else label="DONE BY:">
        <span class="k-font-weight-bold"
          >{{ welcome.w_user_name }} |
          {{ welcome.w_created_at | myGlobalDay() }}</span
        >
      </b-form-group>
    </template>
  </b-modal>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import FilesSimpleTable from "@/views/commons/components/clients/dashboard/options/files-module/components/FilesSimpleTable.vue";
import WorkPlanInputs from "@/views/ce-digital/sub-modules/customer-service/views/clients/dashboard/options/task-notes-module/components/WorkPlanInputs.vue";
import MonthlyPaymentFeeComponent from "@/views/ce-digital/sub-modules/customer-service/views/clients/dashboard/options/task-notes-module/components/MonthlyPaymentFeeComponent";
import { mapActions, mapGetters } from "vuex";
import NotesServices from "@/views/commons/components/first-notes/services/notes.service";
import moment from "moment";
import ClientsDashboardServices from "@/views/commons/components/clients/dashboard/services/clients.dashboard.services";
import FinancialCommissionsService from "@/views/ce-digital/sub-modules/financial/view/commissions/financial-commissions.service";

export default {
  components: {
    VueGoogleAutocomplete,
    FilesSimpleTable,
    WorkPlanInputs,
    MonthlyPaymentFeeComponent,
  },
  props: {
    // client: {},
  },
  data() {
    return {
      onControl: false,
      validator: false,
      disabled: false,
      diames: "",
      idAccount: "",
      information: "",
      checkPresentation: "",
      idWorkPlanForWelcome: null,
      checkRecommendations: [],
      legalStatuses: [
        { text: "U.S Citizen", value: 1 },
        { text: "Residente Alien", value: 2 },
        { text: "Non-Resident Alien", value: 3 },
        { text: "Student Green Card", value: 4 },
        { text: "Work Green Card", value: 5 },
      ],
      checkFileYes: "",
      goalOptions: [],
      states: [],
      goalsedit: false,
      typeGoal: [],
      vMoney: {
        decimal: ".",
        thousands: ",",
        prefix: "$",
        precision: 2,
        masked: false,
      },
      isBusy: false,
      fields: [
        {
          key: "credit",
          label: "Creditors",
        },
        {
          key: "account",
          label: "# Account",
        },
        {
          key: "balance",
          label: "Total Balance",
        },
      ],
      fieldsCreditCard: [
        {
          key: "name_card",
          label: "Card Name",
        },
        {
          key: "n_card",
          label: "Card Number",
        },
        {
          key: "card_expi_month",
          label: "MM",
        },
        {
          key: "card_expi_year",
          label: "YY",
        },
        {
          key: "cvc",
          label: "CVC",
        },
      ],
      checkFileNo: "",
      checkProInfor: "",
      checkGoalsArray: [],
      checkGoals: "",
      checkCreditors: "",
      checkInformation: "",
      welcome: {
        statuswelcome: null,
        id_note: null,
        information: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        cards: "",
        email: "",
        dob: "",
        ssn: "",
        itin: "",
        cpn: "",
        client_status_l: "",
        legal_status: "",
        pending: "",
        client_fee_amount: "",
      },
      informationedit: "",
      checkDba: "",
      dbaedit: "",
      checkAddress: "",
      adressedit: false,
      checkPersonSsn: "",
      ssnedit: "",
      checkPersonDob: "",
      dobedit: "",
      checkLegal: "",
      statusedit: "",
      checkEmail: "",
      emailedit: "",
      checkPersoAp: "",
      lastName: "",
      checkDoubts: "",
      doubtsedit: "",
      items: [],
      cards: [],
      editorOption: {
        modules: { toolbar: false },
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      note: "DebtSolutionClients/G_NOTES",
      G_TRACKING_ID: "DashboardCustomerServiceStore/G_TRACKING_ID",
      client: "DebtSolutionClients/G_CLIENTS",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  async created() {
    this.onControl = true;
    this.isBusy = true;
    this.idAccount = this.$route.params.idClient;
    await this.getNotesWelcomeDs();
    this.setDataWelcome();
    await this.getStates();
    await this.getListTypeGoal();
    await this.getFirstWorkPlan();

    this.welcome.client_fee_amount = Number(this.welcome.client_fee_amount);
    this.cards = JSON.parse(this.welcome.client_cardnumber);
    this.disabled = this.welcome.w_status === 1;
    this.isBusy = false;
  },
  methods: {
    ...mapActions({
      A_CHANGE_USER_STATUS_SESSION_SUB_MODULE:
        "UserStore/A_CHANGE_USER_STATUS_SESSION_SUB_MODULE",
      A_SET_FINISHED_DASHBOARD_ACTION:
        "DebtSolutionClients/A_SET_FINISHED_DASHBOARD_ACTION",
    }),
    close() {
      this.$emit("close");
    },
    async getFirstWorkPlan() {
      try {
        const data = await ClientsOptionsServices.getFirstWorkPlan({
          idAccount: this.$route.params.idClient,
        });
        if (data.status === 200) {
          if (data.data.length > 0) {
            this.idWorkPlanForWelcome = data.data[0].id;
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    async getStates() {
      try {
        const data = await ClientsOptionsServices.getStatesUSApi();
        if (data.status === 200) {
          this.states = data.data;
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    async getNotesPerYear() {
      const year = moment().format("YYYY");
      const data = await ClientsOptionsServices.getNotesYear({
        id: this.$route.params.idClient,
        year,
      });
      if (data.status === 200) {
        this.notes = data.data;
      }
    },
    async getNotesWelcomeDs() {
      try {
        this.addPreloader();
        const data = await ClientsOptionsServices.getNotesWelcomeDs({
          id: this.client.sale_id,
        });
        if (data.status === 200) {
          this.notes = data.data;
          this.welcome = this.notes[0];
          if (this.information === "") {
            this.information =
              "El motivo de la llamada es para darte la bienvenida formal al departamento de Credit Experts Digital, asi mismo informarle que estare atento a cualquier duda o consulta referente a la formacion del credito. Queremos agradecer la confianza que nos ha brindado al enrrolarse en nuestra compañia.";
          }
          this.removePreloader();
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    async getListTypeGoal() {
      try {
        const response = await NotesServices.getListTypeGoal();
        this.goalOptions = response;
      } catch (error) {
        this.showErrorSwal();
      }
    },
    editInformation() {
      this.informationedit = this.checkInformation === true;
    },
    editGoals() {
      this.goalsedit = this.checkGoals === true;
    },
    editdba() {
      this.dbaedit = this.checkDba === true;
    },
    editadress() {
      this.adressedit = this.checkAddress === true;
    },
    editssn() {
      this.ssnedit = this.checkPersonSsn === true;
    },
    editdob() {
      this.dobedit = this.checkPersonDob === true;
    },
    editstatus() {
      this.statusedit = this.checkLegal === true;
    },
    editemail() {
      this.emailedit = this.checkEmail === true;
    },
    editcard() {
      this.cardedit = this.checkPersoAp === true;
    },
    editDoubts() {
      this.doubtsedit = this.checkDoubts === true;
    },
    setDataWelcome() {
      this.welcome.statuswelcome = this.welcome.w_status;
      this.welcome.id_note = this.welcome.sale_id;
      if (this.checkPresentation === "") {
        this.checkPresentation = this.welcome.w_presentation === 1;
      }
      this.checkProInfor = this.welcome.w_goals === 1;
      this.checkGoals = this.welcome.w_goals === 1;
      this.editGoals();
      this.checkCreditors = this.welcome.w_incovenience === 1;
      this.checkInformation = this.welcome.w_information === 1;
      this.editInformation();
      this.checkDba = this.welcome.w_dba === 1;
      this.editdba();
      this.checkAddress = this.welcome.w_adress === 1;
      this.editadress();
      this.checkPersonSsn = this.welcome.w_ssn === 1;
      this.editssn();
      this.checkPersonDob = this.welcome.w_dob === 1;
      this.editdob();
      this.checkLegal = this.welcome.w_legal_status === 1;
      this.editstatus();
      this.checkEmail = this.welcome.w_email === 1;
      this.editemail();
      this.checkPersoAp = this.welcome.w_card === 1;
      this.editcard();
      this.checkDoubts = this.welcome.w_c_doubts === 1;
      this.editDoubts();
      this.diames = `${
        this.welcome.cf_month_payment <= 9
          ? `0${this.welcome.cf_month_payment}`
          : this.welcome.cf_month_paymentt
      } / ${this.welcome.cf_day_payment}`;
    },

    getAddressData(addressPrincipal) {
      const mainAddress = addressPrincipal.street_number
        ? `${addressPrincipal.street_number} ${addressPrincipal.route}`
        : addressPrincipal.route;
      this.welcome.client_street = mainAddress || " ";
      this.welcome.client_city = addressPrincipal.locality
        ? addressPrincipal.locality
        : " ";
      this.welcome.client_states_eeuu_slug =
        addressPrincipal.administrative_area_level_1
          ? addressPrincipal.administrative_area_level_1
          : "";
      this.welcome.client_zipcode = addressPrincipal.postal_code
        ? addressPrincipal.postal_code
        : "";
    },
    async saveUpdateWelcome() {
      try {
        this.validator = true;
        const result1 = await this.$refs.form.validate();
        if (result1) {
          const result = await this.showConfirmSwal();
          if (result.isConfirmed) {
            this.addPreloader();
            const dt = [];
            dt.push({ number: 1049, value: this.typeGoal });
            const data = await ClientsOptionsServices.saveUpdateWelcomeDs({
              id: this.idAccount,
              presentation: this.checkPresentation,
              idnote: this.welcome.sale_id,
              note: dt,
              pendingg: this.welcome.w_pending,
              c_presentation: this.checkPresentation === true ? 1 : 2,
              c_goals: this.checkGoals === true ? 1 : 2,
              c_inconvenience: this.checkCreditors === true ? 1 : 2,
              c_information: this.checkInformation === true ? 1 : 2,
              c_dba: this.checkDba === true ? 1 : 2,
              c_adress: this.checkAddress === true ? 1 : 2,
              c_person_ssn: this.checkPersonSsn === true ? 1 : 2,
              c_person_dob: this.checkPersonDob === true ? 1 : 2,
              c_legal: this.checkLegal === true ? 1 : 2,
              c_email: this.checkEmail === true ? 1 : 2,
              c_perso_ap: this.checkPersoAp === true ? 1 : 2,
              legalstatus: this.welcome.client_status_l,
              lastname: this.welcome.client_last_name,
              middlename: this.welcome.client_middle_name,
              fisrtname: this.welcome.client_first_name,
              c_dob: this.welcome.client_dob,
              c_emaill: this.welcome.client_email,
              c_doubts: this.welcome.w_doubts,
              cdoubts: this.checkDoubts === true ? 1 : 2,
              userid: this.currentUser.user_id,
              c_street: this.welcome.client_street,
              c_city: this.welcome.client_city,
              c_states_eeuu_slug: this.welcome.client_states_eeuu_slug,
              c_zipcode: this.welcome.client_zipcode,
            });
            if (data.status === 200) {
              const body = {
                commision_type: 1,
                agent_id: this.currentUser.user_id,
                module_id: this.moduleId,
                client_account: this.$route.params.idClient,
              };
              // call commision here
              await FinancialCommissionsService.createCommissionCed(body);
              await this.$store.dispatch("DebtSolutionClients/A_NOTES", {
                id: this.$route.params.idClient,
                year: moment().format("YYYY"),
              });
              if (this.isAgent) {
                await this.changeToSessionActive();
              }
              //TODO test here with chief and agents
              await ClientsDashboardServices.updateTrackingDashboard({
                tracking_id: this.G_TRACKING_ID,
              });
              this.showSuccessSwal();
              this.$emit("reload");
              this.close();
              this.removePreloader();
            }
          }
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    async changeToSessionActive() {
      if (this.isAgent) {
        await ClientsDashboardServices.updateMotiveTypeClient({
          motive_type: 2,
          client_account_id: this.client.account,
        });
        this.A_SET_FINISHED_DASHBOARD_ACTION(1);
        // generates commission for welcome
        try {
          const params = {
            created_by: this.currentUser.user_id,
            sub_module_id: this.moduleId,
            status_sub_module_id: 1,
          };
          const data = await this.A_CHANGE_USER_STATUS_SESSION_SUB_MODULE(
            params
          );
          if (data.status === 200) {
            await this.$router.push({ name: "ce-dashboard-agent" });
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
  },
};
</script>
<style scoped>
* {
  --primary-color: #3f7afa;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px !important;
}
.disable-class {
  pointer-events: none;

  /* for "disabled" effect */
  opacity: 0.5;
}
</style>
<style>
.kk-picker-wrap .k-picker-wrap {
  border-color: transparent !important;
}
.quill-br .ql-container {
  height: 200px !important;
}
</style>
