<template>
  <span>
    <div v-if="isBusy" class="text-center text-primary my-2">
      <b-spinner class="align-middle mr-1" />
      <strong>Loading ...</strong>
    </div>
    <transition name="fade">
      <b-row v-if="!isBusy">
        <b-col cols="12">
          <div v-for="(work, index) in workPlan" :key="index">
            <b-form-group :label="work.question">
              <b-form-textarea
                v-if="statewp === 0"
                :id="'answer' + work.id_question"
                v-model="work.answer"
                :disabled="valorDisabled"
                :name="'answer' + work.id_question"
              />
              <div
                v-if="statewp === 1 && stateedit === false"
                :class="
                  isDarkSkin
                    ? 'textarea-style-work-dark'
                    : 'textarea-style-work'
                "
                :disabled="valorDisabled"
                :name="'answer' + work.id_question"
                v-html="work.answer"
              />
              <b-form-textarea
                v-if="statewp === 1 && stateedit === true"
                :id="'answer' + work.id_question"
                v-model="work.answer"
                :disabled="valorDisabled"
                :name="'answer' + work.id_question"
              />
            </b-form-group>
          </div>
        </b-col>
      </b-row>
    </transition>
  </span>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import { mapGetters } from "vuex";

export default {
  props: {
    clientName: null,
    account: null,
    idWorkPlan: null,
  },
  data() {
    return {
      onControl: false,
      workPlan: [],
      isBusy: false,
      isEditingWork: false,
      statewp: 0,
      valorDisabled: true,
      stateedit: 0,
      moodEditing: true,
      hasBeenUpdated: false,
    };
  },
  async created() {
    this.onControl = true;
    await this.getWorkPlan();
  },
  methods: {
    async getWorkPlan() {
      try {
        if (this.idWorkPlan != null) {
          this.isBusy = true;
          const data = await ClientsOptionsServices.getAllWorkPlan({
            id: this.$route.params.idClient,
            id_ncr: this.idWorkPlan,
          });
          if (data.status === 200) {
            this.workPlan = data.data;
            this.isEditingWork = true;
            this.statewp = 1;
            this.stateedit = false;
            this.isBusy = false;
          }
        } else {
          this.isBusy = true;
          const data = await ClientsOptionsServices.getAllWorkPlanWhenNull({
            id: this.$route.params.idClient,
          });
          if (data.status === 200) {
            this.workPlan = data.data;
            this.isEditingWork = true;
            this.statewp = 0;
            this.stateedit = true;
            this.isBusy = false;
          }
        }
      } catch (e) {
        this.isBusy = false;
        this.showErrorSwal(e);
      }
    },
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  watch: {
    async idWorkPlan() {
      await this.getWorkPlan();
      this.hasBeenUpdated = this.workPlan[0]?.updated_at != null;
    },
  },
};
</script>

<style>
.textarea-style-work {
  border: 1px solid #ccc;
  width: 100%;
  height: 70px;
  border-radius: 5px;
  padding: 0.8rem 1rem;
  overflow: scroll;
}
.textarea-style-work[disabled] {
  border: 1px solid #ccc;
  width: 100%;
  height: 70px;
  border-radius: 5px;
  padding: 0.8rem 1rem;
  overflow: scroll;
  background-color: #efefef;
  opacity: 1;
}
.textarea-style-work-dark {
  border: 1px solid #ccc;
  width: 100%;
  height: 70px;
  border-radius: 5px;
  padding: 0.8rem 1rem;
  overflow: scroll;
}
.textarea-style-work-dark[disabled] {
  border: 1px solid rgba(255, 255, 255, 0.4);
  width: 100%;
  height: 70px;
  border-radius: 5px;
  padding: 0.8rem 1rem;
  overflow: scroll;
  background-color: #17171a;
  opacity: 0.5;
}
</style>
