import { render, staticRenderFns } from "./NotesWelcomeCustomerServiceDigModal.vue?vue&type=template&id=e9d0e13e&scoped=true"
import script from "./NotesWelcomeCustomerServiceDigModal.vue?vue&type=script&lang=js"
export * from "./NotesWelcomeCustomerServiceDigModal.vue?vue&type=script&lang=js"
import style0 from "./NotesWelcomeCustomerServiceDigModal.vue?vue&type=style&index=0&id=e9d0e13e&prod&scoped=true&lang=css"
import style1 from "./NotesWelcomeCustomerServiceDigModal.vue?vue&type=style&index=1&id=e9d0e13e&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e9d0e13e",
  null
  
)

export default component.exports