<template>
  <div>
    <b-row>
      <b-col>
        <b-card class="box-shadow-card">
          <b-card-title>
            <h2><strong>Notes</strong></h2>
          </b-card-title>
          <hr />
          <b-card-text>
            <Notes
              :client="client"
              :currentUser="currentUser"
              :moduleId="moduleId"
              :notes="notes"
            />
          </b-card-text>
        </b-card>
      </b-col>
      <b-col>
        <b-card class="box-shadow-card">
          <b-card-title>
            <h2><strong>Tasks</strong></h2>
          </b-card-title>
          <hr />
          <b-card-text>
            <Tasks
              :client="client"
              :currentUser="currentUser"
              :moduleId="moduleId"
            />
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import Notes from "@/views/commons/components/clients/dashboard/options/task-notes-module/components/Notes.vue";
import Tasks from "@/views/commons/components/clients/dashboard/options/task-notes-module/components/Tasks.vue";
import { mapGetters } from "vuex";

export default {
  name: "TaskNotesNew",
  props: {
    idaccount: null,
    allTasks: null,
    allNotes: null,
    roleid: null,
    modul: null,
    hasAccess: {
      type: Boolean,
      required: false,
    },
    typeAccess: {
      type: Number | null | undefined,
      default: () => 0,
    },
  },
  components: {
    Notes,
    Tasks,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
      notes: "DebtSolutionClients/G_NOTES",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  async created() {
    await this.$store.dispatch("DebtSolutionClients/A_GET_CLIENTS", {
      id: this.$route.params.idClient,
    });
  },
};
</script>
<style scoped>
.box-shadow-card {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
}
</style>
