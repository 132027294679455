<template>
  <div class="d-flex justify-content-between">
    <div>
      <span>MONTHLY PAYMENT</span>
      <h4 class="ml-2 d-inline">
        <b-badge
          style="color: #6e6b7b; padding: 0.7rem; font-size: 14px"
          variant="light"
        >
          $ {{ welcome.c_monthly_amount }}
        </b-badge>
      </h4>
    </div>
    <div style="padding-right: 3.7rem">
      <span>FEE</span>
      <h4 class="ml-2 d-inline">
        <b-badge
          style="color: #6e6b7b; padding: 0.7rem; font-size: 14px"
          variant="light"
        >
          $ {{ welcome.client_fee_amount }}
        </b-badge>
      </h4>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MonthlyPaymentFeeComponent',
  props: {
    welcome: null,
  },
}
</script>

<style scoped>

</style>
