<template>
  <div class="select--custom--container">
    <div
      class="select-main"
      :style="{ background: isDarkSkin ? '#131323' : '#EDF1F8' }"
      @click="open"
    >
      <div class="text">
        <span class="placeholder--text" v-if="!selectedOption">
          {{ placeholder }}
        </span>
        <span class="text-selected" v-else>
          {{ selectedOption.text }}
          <template v-if="selectedOption.badge">
            <b-badge :variant="selectedOption.badge.variant">
              {{ selectedOption.badge.value }}
            </b-badge>
          </template>
        </span>
      </div>
      <div class="icon">
        <feather-icon
          icon="XIcon"
          size="16"
          v-if="selectedOption"
          @click="deselectOption"
        />
      </div>
      <div class="icon">
        <feather-icon :icon="ChevronIcon" size="20" />
      </div>
    </div>
    <ul class="list--options" v-if="openListOptions">
      <li
        v-for="(option, index) in options"
        :key="index"
        @click="optionSelected(option, index)"
        :class="indexOption === index ? 'active' : ''"
      >
        <span v-if="option.icon" class="list--icon">
          <feather-icon :icon="option.icon" size="18" />
        </span>
        <span class="text--option">{{ option.text }}</span>
        <template v-if="option.badge">
          <b-badge :variant="option.badge.variant">
            {{ option.badge.value }}
          </b-badge>
        </template>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "SelectCustom",
  props: {
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: () => "Select an option",
    },
    firstItemDefault: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      openListOptions: false,
      selectedOption: null,
      indexOption: null,
    };
  },
  computed: {
    ChevronIcon() {
      return this.openListOptions ? "ChevronUpIcon" : "ChevronDownIcon";
    },
  },
  methods: {
    open() {
      this.openListOptions = !this.openListOptions;
    },
    closeOnClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.openListOptions = false;
      }
    },
    optionSelected(opt, index) {
      this.selectedOption = opt;
      this.indexOption = index;
      this.openListOptions = false;
      this.sendEventSelected();
    },
    deselectOption() {
      this.selectedOption = null;
      this.sendEventSelected();
    },
    sendEventSelected() {
      this.$emit("selected", this.selectedOption);
    },
  },
  mounted() {
    document.addEventListener("click", this.closeOnClickOutside);
    if (this.firstItemDefault) {
      this.optionSelected(this.options[2], 2);
    }
  },
  destroyed() {
    document.removeEventListener("click", this.closeOnClickOutside);
  },
};
</script>
<style lang="scss" scoped>
.select--custom--container {
  position: relative;
  .select-main {
    display: flex;
    justify-content: space-between;
    // background: rgb(248, 241, 237);
    padding: 10px 5px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    .text {
      flex: 1;
      text-align: center;
    }
  }
  .list--options {
    position: absolute;
    top: 120%;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2), 0px 5px 20px rgba(0, 0, 0, 0.1);
    left: 0;
    right: 0;
    border-radius: 10px;
    list-style: none;
    padding: 0;
    z-index: 500;
    li {
      padding: 8px 5px 8px 15px;
      display: flex;
      align-items: center;
      align-content: center;
      cursor: pointer;
      color: #606060;
      font-weight: 600;
      justify-content: space-between;
      .list--icon {
        margin-right: 5px;
      }
      .text--option {
        flex: 1;
      }
    }
    li:hover {
      background: #e7f1ff;
      color: #6997fb;
    }
    li.active {
      background-color: #3f7afa;
      color: #fff;
      transition: background-color 0.2s ease, border 0.2s ease;
    }
    :first-child:hover,
    :first-child.active {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    :last-child:hover,
    :last-child.active {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}
</style>
